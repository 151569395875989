@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Work+Sans:wght@400;700&display=swap);
.container {
    --show-view-link: none;
    --container-grid-columns: max-content 1fr max-content max-content max-content;
    grid-template-columns: var(--container-grid-columns);
}

.linkClass {
    display: var(--show-view-link);
}
.container {
    --show-view-link: none;
    --container-grid-columns: 'max-content minmax(0px, 1fr) max-content max-content max-content max-content max-content';
    grid-template-columns: var(--container-grid-columns);
}

.linkClass {
    display: var(--show-view-link);
    grid-column-start: 7;
    margin-right: 20px;
}

* {
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: inherit;
}

body {
  font-family: Roboto, sans-serif;
}

ul,
ol {
  list-style: none;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.MuiMenu-list {
  max-height: 400px;
}

.react-date-picker__calendar {
  z-index: 10 !important;
}

