.container {
    --show-view-link: none;
    --container-grid-columns: 'max-content minmax(0px, 1fr) max-content max-content max-content max-content max-content';
    grid-template-columns: var(--container-grid-columns);
}

.linkClass {
    display: var(--show-view-link);
    grid-column-start: 7;
    margin-right: 20px;
}
